<template>
  <div v-if="$route.path != '/zmudri-do-skol'">
    <b-navbar
      fixed="top"
      toggleable="xl"
      class="d-flex justify-content-between align-items-center"
    >
      <div class="d-flex flex-row align-items-center">
        <b-navbar-brand
          @click="closeOverlays"
          to="/"
          :aria-label="$t('homepage')"
        >
          <svg-zmudri-logo class="logo w-color-primary" />
        </b-navbar-brand>
        <div class="v-line"></div>
        <b-navbar-nav class="-logo align-items-center">
          <b-nav-item-dropdown
            class="language-select ml-xl-0 ml-2"
            :text="currentLanguage"
            right
          >
            <b-dropdown-item
              v-for="(lang, key) in languages"
              :key="key"
              href="#"
              @click.prevent="changeLanguage(key)"
            >
              {{ key }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
      <div class="d-flex align-items-center">
        <b-navbar-toggle
          v-if="isProfileSubpage || isCoursesPage"
          :target="isProfileSubpage ? 'ProfileSidebar' : 'CoursesSidebar'"
        >
          <svg-user-icon v-if="isProfileSubpage" class="w-color-secondary" />
          <b-icon
            v-if="isCoursesPage"
            icon="filter-circle-fill"
            class="w-color-secondary"
          />
        </b-navbar-toggle>

        <b-navbar-toggle target="NavbarSearch" ref="searchToggle">
          <svg-search-icon class="w-color-orange" />
        </b-navbar-toggle>

        <b-navbar-toggle target="nav-collapse" ref="menuToggle">
          <div class="animated-hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </b-navbar-toggle>
      </div>

      <b-collapse id="NavbarSearch" is-nav @show="onShow" @hide="onHide">
        <b-nav-form class="d-xl-none search-form mt-3" @submit.prevent>
          <CourseSearch />
        </b-nav-form>
      </b-collapse>

      <b-collapse id="nav-collapse" is-nav @show="onShow" @hide="onHide">
        <b-navbar-nav
          class="middle-nav align-items-xl-center justify-content-center"
        >
          <b-nav-item
            v-if="!isLoggedIn || isAdmin"
            @click="closeOverlays"
            to="/zmudri-do-skol"
            class="a-btn"
            >{{ $t("Zmudri do škôl") }}</b-nav-item
          >
          <b-nav-item
            v-if="!isLoggedIn || isAdmin"
            @click="closeOverlays"
            class="a-btn"
            :to="$route.path === '/pre-ucitelov' ? '/' : '/pre-ucitelov'"
          >
            {{
              $route.path === "/pre-ucitelov"
                ? $t("Pre študentov")
                : $t("for-teachers")
            }}
          </b-nav-item>
          <b-nav-item @click="closeOverlays" to="/kurzy" class="a-btn">{{
            $t("our-courses")
          }}</b-nav-item>
          <b-nav-item-dropdown
            @click="closeOverlays"
            class="a-btn opacity-100"
            :text="$t('O nás')"
          >
            <b-dropdown-item to="/o-nas">{{ $t("O nás") }}</b-dropdown-item>
            <b-dropdown-item to="/partneri">{{
              $t("Partneri")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-form class="search-form d-xl-block d-none" @submit.prevent>
            <CourseSearch />
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav class="align-items-xl-center mr-xl-2">
          <template v-if="isLoggedIn">
            <b-nav-item :to="isAdmin ? '/triedy' : '/oblubene-kurzy'">
              <div
                class="ml-2 d-flex align-items-center w-color-dark-gray w-hover-color-secondary"
              >
                {{ isAdmin ? $t("Moje triedy") : $t("Moje kurzy") }}
                <span class="z-icon-wrapper">
                  <svg-group-icon v-if="isAdmin" />
                  <svg-heart-icon v-else />
                </span>
              </div>
            </b-nav-item>
            <div class="v-line d-none d-xl-block"></div>
            <b-nav-item :to="isAdmin ? '/news' : '/profil'">
              <div
                class="d-flex align-items-center w-color-dark-gray w-hover-color-secondary"
              >
                <span class="z-icon-wrapper">
                  <svg-user-icon class="profile-icon" />
                </span>
                {{ $t("Profil") }}
              </div>
            </b-nav-item>
          </template>
          <div v-else class="d-flex flex-row align-center p-2">
            <b-button
              variant="outline-primary"
              @click="openLoginProccess"
              class="mr-3 text-nowrap"
              >{{ $t("Prihlásiť sa") }}</b-button
            >
            <b-button variant="secondary" @click="navToRegistration">{{
              $t("Registrácia")
            }}</b-button>
            <b-collapse
              id="login-collapse"
              class="mt-2"
              v-model="isRolloverVisible"
            >
              <b-card>
                <Login
                  class="login-form"
                  :showHeader="false"
                  @afterLogin="closeOverlays"
                  @afterForgotPassword="closeOverlays"
                  @afterRegistration="closeOverlays"
                />
              </b-card>
            </b-collapse>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <Breadcrumbs />
    <div
      class="overlay"
      :class="{ '-visible': isOverlayVisible }"
      @click="overlayClick"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Translations from "/utils/translate/translations.ts";

import * as Sentry from "@sentry/vue";

export default {
  components: {
    CourseSearch: () => import("/components/CourseSearch"),
    Breadcrumbs: () => import("/components/Breadcrumbs.vue"),
    "svg-search-icon": () => import("/assets/icons/search.svg?inline"),
    "svg-heart-icon": () => import("/assets/icons/heart.svg?inline"),
    "svg-user-icon": () => import("/assets/icons/user.svg?inline"),
    "svg-group-icon": () => import("/assets/icons/groups.svg?inline"),
    "svg-zmudri-logo": () => import("/assets/icons/zmudriLogo.svg?inline"),
    // 'svg-light-bulb-icon': 	() => import('/assets/icons/lightBulb.svg?inline'),
    Login: () => import("/components/Login.vue"),
  },

  data() {
    return {
      isOverlayVisible: false,
      isRolloverVisible: false,
      courses: null,
      currentLanguage: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
    isProfileSubpage() {
      const profileUrls = this.isAdmin
        ? this.profileUrls.teacher
        : this.profileUrls.student;
      // return !!Object.values(profileUrls).find((url) => {
      //   return this.$route.fullPath.indexOf(url) > -1;
      // });
      return profileUrls.includes(this.$route.fullPath);
    },
    isCoursesPage() {
      return this.$route.fullPath.indexOf("/kurzy") > -1;
    },
    languages() {
      return Translations.getLocales();
    },
  },

  created() {
    this.EventBus.$on("locked-content-accessed", () => {
      this.openLoginProccess();
    });
    window.onresize = () => {
      if (window.innerWidth < 1200) {
        this.closeOverlays();
      }
    };

    this.currentLanguage = Translations.getLocale();
  },

  methods: {
    async logout() {
      this.$eventLogger.userLog("user.logout", { timestamp: new Date() });
      try {
        await this.$store.dispatch("auth/logout");
        if (this.$route.path != "/") this.$router.push("/");
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },

    changeLanguage(lang) {
      Translations.setLocale(lang);
      this.currentLanguage = Translations.getLocale();
    },

    onShow() {
      this.isOverlayVisible = true;
    },

    onHide() {
      const searchToggle = this.$refs.searchToggle.$el;
      const menuToggle = this.$refs.menuToggle.$el;
      if (
        !searchToggle.classList.contains("not-collapsed") &&
        !menuToggle.classList.contains("not-collapsed")
      ) {
        this.closeOverlays();
      }
    },

    collapseMenu() {
      const searchToggle = this.$refs.searchToggle.$el;
      const menuToggle = this.$refs.menuToggle.$el;
      if (searchToggle.classList.contains("not-collapsed")) {
        searchToggle.click();
      }
      if (menuToggle.classList.contains("not-collapsed")) {
        menuToggle.click();
      }
    },

    openLoginProccess() {
      if (window.innerWidth < 1200) {
        if (this.$route.path != "/prihlasit-sa")
          this.$router.push("/prihlasit-sa");
        this.collapseMenu();
        return;
      }
      window.scrollTo(0, 0);
      this.isOverlayVisible = !this.isOverlayVisible;
      this.isRolloverVisible = !this.isRolloverVisible;
    },

    navToRegistration() {
      this.closeOverlays();
      if (this.$route.path != "/registracia") this.$router.push("/registracia");
    },

    closeOverlays() {
      this.isOverlayVisible = false;
      this.isRolloverVisible = false;
    },

    overlayClick() {
      this.collapseMenu();
      this.closeOverlays();
    },
  },
  watch: {
    isLoggedIn() {
      this.closeOverlays();
    },
  },
};
</script>

<style lang="scss" scoped>
#login-collapse {
  position: absolute;
  left: 0;
  top: 72px;
  width: 100vw;

  @media only screen and (min-width: 1680px) {
    top: 120px;
  }

  &::v-deep {
    .card {
      border-radius: 0px;
      border: none;
    }

    .card-body {
      padding: 20px 0px 0px 0px;
      cursor: default;
      -webkit-box-shadow: 0px 60px 40px 20px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 60px 40px 20px rgba(0, 0, 0, 0.3);
    }
  }

  .login-form {
    padding: 0px 300px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #2b2e4a;
}
nav {
  width: 100vw;
  background-color: #ffffff;
  position: relative;

  z-index: 1030;
  padding: 2rem 3rem;

  .router-link-active {
    font-weight: bold;
  }

  .a-btn:hover {
    color: inherit;
    text-decoration: none;
  }

  .v-line {
    margin: 0 0;
  }

  ::v-deep .a-btn,
  .btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  @media only screen and (max-width: 1679px) {
    padding: 0.5rem 1.5rem;
  }

  @media only screen and (max-width: 575px) {
    padding: 0.5rem 1rem;

    .logo {
      max-width: 6rem;
    }
  }
}

.overlay {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
  content: "";
  z-index: -1;
  background-color: rgba(var(--a-color-black-rgb), 0.6);
  transition: 0.5s ease;

  &.-visible {
    z-index: 1029;
    opacity: 1;
  }
}

.navbar-toggler {
  border-width: 0;
  padding: 0.2rem 0.5rem;

  .animated-hamburger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 2rem;
    height: 1.5rem;

    span {
      display: block;
      height: 4px;
      width: 100%;
      background: var(--a-color-primary);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        transform-origin: right center;
      }

      &:nth-child(2) {
        transform-origin: right center;
      }

      &:nth-child(3) {
        transform-origin: right center;
      }
    }
  }

  &.not-collapsed {
    .animated-hamburger {
      span {
        &:nth-child(1) {
          transform: rotate(-45deg) translate(0px, 0px);
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg) translate(3px, 2px);
        }
      }
    }
  }

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.nav-link {
  white-space: nowrap;
  font-family: "Boing", sans-serif;
}

.left-nav {
  max-width: 20%;
}

.logo {
  max-width: 100%;
}

.middle-nav {
  flex-grow: 1;
}

.search-form {
  flex: 1 0 0;
  max-width: 745px;
  margin: 0 0.5rem;

  form {
    width: 100%;
  }
}

.profile-icon {
  max-height: 22px;
}

.navbar-icon {
  margin-left: 0.75rem;
  svg {
    height: 40px;
    width: 40px;
  }
}

.language-select {
  text-transform: uppercase;
}

@media only screen and (max-width: 1400px) {
  nav {
    .a-btn {
      padding: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-nav {
    &.-logo {
      flex-direction: row;

      ::v-deep .dropdown-menu {
        position: absolute;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .v-line {
    height: 2.75rem;
  }
}

.opacity-100,
.opacity-100::v-deep * {
  color: var(--a-color-black);
}

::v-deep .nav-item {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
